import io from "socket.io-client";
import {
  setAuthStatus,
  setConnectionStatus,
  setCurrentPin,
  setCurrentRole,
  setCurrentUserId,
} from "./struct/app/actions";
import { create, remove, update } from "./struct/entities/actions";
import forOwn from "lodash.forown";

import ENTS from "./struct/entities";
import { ROLES } from "./struct/entities/role";
import STRUCT from "./struct";
import { saveExcelFile } from "../utils/saveExcelFile";

import {
  getSavedCurrentUserId,
  removeCurrentUserId,
  saveCurrentUserId,
} from "./storage";

export default class Connection {
  static RECONNECT_TIMEOUT = 10000;
  static CONNECTION_OPTS = {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
    path: process.env.REACT_APP_SIO_PATH || "",
  };
  static get APIs() {
    const API_URL = process.env.REACT_APP_API_URL || "";
    const URL =
      process.env.NODE_ENV === "production"
        ? API_URL
        : "http://localhost:8000/";
    const SIO_URL = process.env.REACT_APP_SIO_URL || URL;

    return {
      URL,
      SIO_URL,
      AUTH: `${URL}auth`,
      UPLOAD_REPS_FILE: `${URL}upload-reps`,
      UPLOAD_QUESTIONS_FILE: `${URL}upload-questions`,
      EXPORT_RESULTS: `${URL}export-results`,
      SEND_TEST_PINS: `${URL}send-test-pins`,
      LOGOUT: `${URL}exit`,
      ALL_AUTO_LOGOUT: `${URL}auto-logout`,
    };
  }

  static get SERVER_EVENTS() {
    return {
      HELLO: "AUTH_HELLO",
      CONNECT: "CONNECTION_SUCCEEDED",
      NOT_AUTHORIZED: "AUTH_WHO_ARE_YOU",
      INIT: "INITED",
      CREATE: "CREATED",
      UPDATE: "UPDATED",
      REMOVE: "DELETED",
      ERROR: "ERROR",
      TROUBLE: "TROUBLE",
    };
  }

  static get EVENTS() {
    return {
      INIT: "INIT",
      CREATE: "CREATE",
      UPDATE: "UPDATE",
      REMOVE: "DELETE",
    };
  }

  static ENTITIES(role) {
    const COMMON = [ENTS.AGENT];
    const ENTITIES = {
      [ROLES.ADMIN]: [
        ...COMMON,
        ENTS.ROLE,
        ENTS.ADMIN,
        ENTS.QUESTIONS,
        ENTS.TESTS,
        ENTS.TESTS_RESULTS,
        ENTS.CATEGORIES,
        ENTS.LINES,
        ENTS.DIRECTIONS,
      ],
      [ROLES.AGENT]: [
        ...COMMON,
        ENTS.QUESTIONS,
        ENTS.TESTS,
        ENTS.TESTS_RESULTS,
        ENTS.CATEGORIES,
      ],
    };

    return ENTITIES[role] || [];
  }

  constructor(store) {
    this.store = store;
    this.connect();
  }

  connect() {
    if (this._connection) {
      this._connection.removeAllListeners();
      this._connection.disconnect();
    }
    this._connection = io(
      this.constructor.APIs.SIO_URL,
      this.constructor.CONNECTION_OPTS
    );
    this.configure();
  }

  configure() {
    if (!this._connection) {
      return;
    }

    const SERVER_EVENTS = this.constructor.SERVER_EVENTS;

    this._connection.on("connect", () => {
      setConnectionStatus(this.store, true);
    });

    this._connection.on(SERVER_EVENTS.CONNECT, (data) => {
      // setConnectionStatus(this.store, true);
      console.groupCollapsed("BUILD INFORMATION");
      console.log("BACKEND BUILD ID: ", data.buildId);
      console.log(
        "FRONTEND BUILD ID: ",
        process.env.REACT_APP_BUILD_ID || "dev"
      );
      console.groupEnd();
    });

    this._connection.on("connect_error", () => {
      setAuthStatus(this.store, false);
    });

    this._connection.on("reconnect_failed", () => {
      setAuthStatus(this.store, false);
      setConnectionStatus(this.store, false);
    });

    this._connection.on("disconnect", () => {
      setTimeout(() => {
        if (this._connection.disconnected) {
          setAuthStatus(this.store, false);
          setConnectionStatus(this.store, false);
        }
      }, this.constructor.RECONNECT_TIMEOUT);
    });
    this._connection.on(SERVER_EVENTS.ERROR, (msg) => {
      if (typeof msg === "object" && msg.code === null) {
        setAuthStatus(this.store, false);
        setConnectionStatus(this.store, false);
      }
    });
    this._connection.on(SERVER_EVENTS.NOT_AUTHORIZED, () => {
      setAuthStatus(this.store, false);
      console.warn("Socket connection warning: you are not authorized");
    });
    this._connection.on(SERVER_EVENTS.HELLO, (authResult) => {
      const savedCurrUserId = getSavedCurrentUserId();
      if (savedCurrUserId && authResult.user_id !== savedCurrUserId) {
        this.logout();
      } else {
        setAuthStatus(this.store, true);
        setCurrentRole(this.store, authResult.role);
        setCurrentUserId(this.store, authResult.user_id);
        saveCurrentUserId(authResult.user_id);
        this.init(authResult.role);
      }
    });
    this._connection.on(SERVER_EVENTS.INIT, (entities) => {
      console.log("entities", entities);
      if (process.env.NODE_ENV === "development") {
        console.log(entities);
      }
      forOwn(entities, (data, entity) => {
        this.store.select([STRUCT.ENTITIES, entity]).set(data);
      });
    });
    this._connection.on(SERVER_EVENTS.UPDATE, (entity, data) => {
      update(this.store, entity, data);
    });
    this._connection.on(SERVER_EVENTS.CREATE, (entity, data) => {
      create(this.store, entity, data);
    });
    this._connection.on(SERVER_EVENTS.REMOVE, (entity, data) => {
      remove(this.store, entity, data);
    });
    this._connection.on(SERVER_EVENTS.ERROR, (err) => {
      console.error(err);
    });
    this._connection.on(SERVER_EVENTS.TROUBLE, (err) => {
      alert(err.msg);
    });
  }

  setPin(pin) {
    removeCurrentUserId();
    const body = new FormData();
    body.append("pin", pin);

    return fetch(this.constructor.APIs.AUTH, {
      method: "POST",
      credentials: "include",
      body,
    })
      .then((response) => {
        if (response.ok) {
          setCurrentPin(this.store, pin);
          this.connect();
        } else {
          setCurrentPin(this.store, "");
          setAuthStatus(this.store, false);
          response.text().then(function (text) {
            if (text) {
              alert(text);
            }
          });
        }

        return true;
      })
      .catch(() => {
        setAuthStatus(this.store, false);
      });
  }

  sendTestPins(testId, siteUrl, users) {
    const body = new FormData();
    body.append("testId", testId);
    body.append("siteUrl", siteUrl);
    if (users) {
      body.append("users", JSON.stringify(users));
    }

    return fetch(this.constructor.APIs.SEND_TEST_PINS, {
      method: "POST",
      credentials: "include",
      body,
    })
      .then(async (response) => {
        if (response.ok) {
          alert("Pins were sent successfully!");
        } else {
          alert("Something went wrong! Pins were not sent.");
        }
      })
      .catch((err) => {
        alert(`Something went wrong! Pins were not sent. Error: ${err}`);
      });
  }

  uploadRepsFile(file) {
    const formData = new FormData();
    formData.append("datasheet", file);

    return fetch(this.constructor.APIs.UPLOAD_REPS_FILE, {
      method: "POST",
      credentials: "include",
      body: formData,
    }).then((response) => {
      if (response.status === 500) {
        response.text().then((text) => {
          alert(text);
        });
      }
      // Get updated data
      this._connection.emit(this.constructor.EVENTS.INIT, [ENTS.AGENT]);
    });
  }

  uploadQuestionsFile(file) {
    const formData = new FormData();
    formData.append("datasheet", file);

    return fetch(this.constructor.APIs.UPLOAD_QUESTIONS_FILE, {
      method: "POST",
      credentials: "include",
      body: formData,
    }).then((response) => {
      if (response.status === 500) {
        response.text().then((text) => {
          alert(text);
        });
      }
      // Get updated data
      this._connection.emit(this.constructor.EVENTS.INIT, [
        ENTS.QUESTIONS,
        ENTS.LINES,
        ENTS.CATEGORIES,
        ENTS.DIRECTIONS,
      ]);
    });
  }

  exportResults(testId) {
    const body = new FormData();
    body.append("testId", testId);

    return fetch(this.constructor.APIs.EXPORT_RESULTS, {
      method: "POST",
      credentials: "include",
      body,
    })
      .then((response) => response.arrayBuffer())
      .then(saveExcelFile);
  }

  init(role) {
    this._connection.emit(
      this.constructor.EVENTS.INIT,
      this.constructor.ENTITIES(role)
    );
  }

  logout() {
    removeCurrentUserId();
    setAuthStatus(this.store, false);
    return fetch(this.constructor.APIs.LOGOUT, {
      method: "GET",
      credentials: "include",
    });
  }

  autoLogout(exerciseId) {
    const body = new FormData();
    body.append("exerciseId", exerciseId);

    return fetch(this.constructor.APIs.ALL_AUTO_LOGOUT, {
      method: "POST",
      credentials: "include",
      body,
    }).then((response) => {
      if (response.ok) {
        console.log("Logout users were successfully!");
      } else {
        console.log("Something went wrong!");
      }
    });
  }

  update(entity, data) {
    this._connection.emit(this.constructor.EVENTS.UPDATE, entity, data);
  }

  create(entity, data) {
    this._connection.emit(this.constructor.EVENTS.CREATE, entity, data);
  }

  remove(entity, data) {
    this._connection.emit(this.constructor.EVENTS.REMOVE, entity, data);
  }
}
